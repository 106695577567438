import * as React from "react"
import { FunctionComponent } from "react"

import { LandingComponent } from "./LandingComponent"
import AboutMDX from "./about/about.mdx"

export const About: FunctionComponent = props => {
    return (
        <LandingComponent {...props} heading="About Me">
            <AboutMDX />
        </LandingComponent>
    )
}
