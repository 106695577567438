import * as React from "react"
import { FunctionComponent, useEffect, useRef, useState } from "react"
import { animated, useSpring } from "react-spring"
import styled from "styled-components"

import { Theme } from "../styles/Theme"

type IntroProps = {
    onVisibilityChanged: (visible: boolean) => void
}

const Wrapper = styled.div`
    z-index: 1;
`

const Top = styled.div`
    height: 66vh;
    background-color: ${props => props.theme.colors.primary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5%;
`

const TopText = styled.div`
    display: flex;
    align-items: flex-end;
`

const TitleText = styled.h1`
    margin: 0;
    color: ${Theme.colors.text};
    font-family: mighty-mtn;
    font-size: 5em;
    font-weight: lighter;
`

const Name = styled(animated.h1)`
    color: white;
    padding-left: 15px;
    margin: 0;
    font-family: mighty-mtn;
    font-size: 5em;
    font-weight: lighter;
`

const clamp = (val: number, min: number, max: number) => Math.max(min, Math.min(max, val))

export const Intro: FunctionComponent<IntroProps> = props => {
    const [animationDelay, setAnimationDelay] = useState(500)
    const [scrollRatio, setScrollRatio] = useState(0)
    const [scrollStarted, setScrollStarted] = useState(false)
    const waveRef = useRef<HTMLDivElement>(null)
    const { name } = useSpring({
        name: "translateX(0)",
        from: {
            name: "translateX(100vw)"
        },
        delay: animationDelay,
        onRest: () => setAnimationDelay(0),
        config: {
            mass: 1,
            tension: 180,
            friction: 20
        }
    })

    const topHeight = window.innerHeight * 0.66

    const onScroll = () => {
        setScrollStarted(true)
        const waveNode = waveRef.current
        const headerHeight = 64 * 3
        if (!waveNode) {
            return
        }

        const { offsetHeight } = waveNode
        const { top: distanceFromTop } = waveNode.getBoundingClientRect()

        const offset = distanceFromTop + offsetHeight / 2

        props.onVisibilityChanged(offset >= headerHeight)

        let newScrollRatio = distanceFromTop / (topHeight + headerHeight)
        newScrollRatio = clamp(newScrollRatio, 0, 1)

        if (newScrollRatio !== scrollRatio) {
            setScrollRatio(newScrollRatio)
        }
    }

    const animateEntry = () => {
        const ratio = scrollRatio ?? 0
        if (!scrollStarted && ratio <= 1) {
            setTimeout(() => setScrollRatio(ratio + 0.02), ratio === 0 ? 500 : 2)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", onScroll)
        animateEntry()

        return () => window.removeEventListener("scroll", onScroll)
    })

    const interpolateValueWithScrollRatio = (y1: number, y2: number) => {
        return scrollRatio === undefined ? y2 : (y2 - y1) * scrollRatio + y1
    }

    const topClampX = interpolateValueWithScrollRatio(100, 200)
    const bottomClampX = interpolateValueWithScrollRatio(100, 0)

    return (
        <Wrapper>
            <Top>
                <TopText>
                    <TitleText>
                        Hi!
                        <br />
                        I&apos;m
                    </TitleText>
                    <Name style={{ transform: name }}>CAmPBELL</Name>
                </TopText>
                <TitleText>Software Engineer</TitleText>
            </Top>
            <div ref={waveRef}>
                <svg viewBox="0 0 1000 200">
                    <path
                        d={`M 0,100 C 500,${topClampX} 500,${bottomClampX} 1000,100 L 1000,0 L 0,0`}
                        stroke="none"
                        fill={Theme.colors.primary}
                    />
                    <path d={`M 0,100 C 500,${topClampX} 500,${bottomClampX} 1000,100`} stroke="none" fill="none" />
                    Sorry, your browser does not support inline SVG.
                </svg>
            </div>
        </Wrapper>
    )
}
