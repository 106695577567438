import * as React from "react"
import { FunctionComponent } from "react"
import { JackInTheBox } from "react-awesome-reveal"
import styled from "styled-components"

import { HoverHeading } from "../components/HoverHeading"

type LandingComponentProps = {
    heading?: string
    blurb?: any
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    padding: 20vh 10vw;
    z-index: 1;
    @media only screen and (max-width: 768px) {
        padding: 15vh 2vw;
    }
`

const Paper = styled.div`
    border-radius: 20px;
    background-color: ${props => props.theme.colors.paper};
    padding: 32px;
    margin: 32px;
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 60vw;
`

export const LandingComponent: FunctionComponent<LandingComponentProps> = ({ children, heading, blurb }) => (
    <Wrapper>
        <JackInTheBox triggerOnce>
            <Paper className="paper">
                {heading && <HoverHeading>{heading}</HoverHeading>}
                {children}
            </Paper>
        </JackInTheBox>
    </Wrapper>
)
