import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Hi!`}</strong>{` I'm Campbell Gorman, a Software Engineer studying and working in Sydney. While I love building digital solutions in a variety of contexts, my strongest passion lies in Android development and novel applications of neuroscience hardware (bonus points if they are combined).`}</p>
    <p>{`Over the past five years, I have balanced my Bachelor of Engineering at `}<a parentName="p" {...{
        "href": "https://www.uts.edu.au/"
      }}>{`UTS`}</a>{` alongside full-time software development at `}<a parentName="p" {...{
        "href": "https://asap.dataforce.com.au/"
      }}>{`Dataforce Services`}</a>{`. This has allowed me to hone my engineering skills alongside senior developers and researchers while working on some `}<a parentName="p" {...{
        "href": "./projects"
      }}>{`awesome projects`}</a>{`.`}</p>
    <p>{`Here are a few technologies I have been working with recently:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><ul>{` `}<li><p>{`Android`}</p></li>{` `}<li><p>{`React Native`}</p></li>{` `}<li><p>{`React`}</p></li>{` `}<li><p>{`Unity`}</p></li>{` `}</ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul>{` `}<li><p>{`Kotlin`}</p></li>{` `}<li><p>{`Typescript`}</p></li>{` `}<li><p>{`C#`}</p></li>{` `}<li><p>{`Python`}</p></li>{` `}</ul></td>
        </tr>
      </tbody>
    </table>
    <p>{`Moving forward I intend to continue mobile applications' development in a commercial context while undertaking a doctorate in neuroscience-based software engineering. This means I am not currently seeking new opportunities, but I am always open to discussion!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      