import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`While I am not actively looking for any new opportunities, I am always open for a discussion. The best way to get in contact with me is by email, although I am active on all the following social media. If you'd like to know more about any of my projects or would like to discuss something you are working on, please drop me a line, I'd love to chat!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      