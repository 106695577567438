import * as React from "react"
import { FunctionComponent, useState } from "react"
import styled from "styled-components"

import { Footer } from "../components/Footer"
import { Header } from "../components/Header"
import { Theme } from "../components/Theme"
import { About } from "../landing/About"
import { Background } from "../landing/Background"
import { Contact } from "../landing/Contact"
import { Intro } from "../landing/Intro"
import { Projects } from "../landing/Projects"

console.log(`
   _____ __  __  _____    _____ ____  _____  ______ 
  / ____|  \\/  |/ ____|  / ____/ __ \\|  __ \\|  ____|
 | |    | \\  / | |  __  | |   | |  | | |  | | |__   
 | |    | |\\/| | | |_ | | |   | |  | | |  | |  __|  
 | |____| |  | | |__| | | |___| |__| | |__| | |____ 
  \\_____|_|  |_|\\_____|  \\_____\\____/|_____/|______|
                                                    
 This site was designed and built by Campbell Gorman
`)

const Wrapper = styled.div`
    position: relative;
    overflow: auto;
    padding-bottom: 20rem;
`

const Index: FunctionComponent = () => {
    const [headerVisible, setHeaderVisible] = useState(false)

    const onIntroVisibilityChanged = (visible: boolean) => setHeaderVisible(!visible)

    return (
        <Theme>
            {(isDarkMode, toggleMode) => (
                <>
                    <Background />
                    <Wrapper>
                        <Header visible={headerVisible} onToggleDarkMode={toggleMode} isDarkMode={isDarkMode} />
                        <Intro onVisibilityChanged={onIntroVisibilityChanged} />
                        <About />
                        <Projects />
                        <Contact />
                        <Footer />
                    </Wrapper>
                </>
            )}
        </Theme>
    )
}

export default Index
