import * as React from "react"
import { FunctionComponent, HTMLAttributes } from "react"
import styled from "styled-components"

const Letter = styled.h1`
    color: ${props => props.theme.colors.text};
    transition: all 0.2s;
    margin: 0;
    font-family: mighty-mtn;
    font-size: 5em;
    font-weight: lighter;
    :hover {
        color: ${props => props.theme.colors.primary};
        transition: all 0.1s;
        transform: translateY(-10px);
    }
    :active {
        color: ${props => props.theme.colors.text};
        transition: all 0.2s;
        transform: translateY(0px);
    }
    cursor: pointer;
`

const Wrapper = styled.div`
    display: flex;
    align-items: flex-end;
`

export const HoverHeading: FunctionComponent<HTMLAttributes<unknown>> = props => {
    const { children } = props
    if (typeof children !== "string") {
        throw new Error("Hover heading children must be string")
    }

    const letters = children.split("").map((letter, index) => {
        const isSpace = letter === " "
        return <Letter key={letter + index}> {isSpace ? "\u00A0" : letter} </Letter>
    })

    return (
        <div {...props}>
            <Wrapper>{letters}</Wrapper>
        </div>
    )
}
