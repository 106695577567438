import * as React from "react"
import { FunctionComponent, memo, useMemo } from "react"
import { animated } from "react-spring"
import styled, { keyframes } from "styled-components"

type BubbleProps = {
    $random: (limit?: number) => number
}

const animationDuration = 3
const particleSize = 7

const move = keyframes`
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
`

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.theme.colors.background};
`

const Bubble = styled(animated.div)<BubbleProps>`
    width: ${particleSize}vmin;
    height: ${particleSize}vmin;
    border-radius: ${particleSize}vmin;
    backface-visibility: hidden;
    position: absolute;
    animation-name: ${move};
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    color: ${props => props.theme.colors.light};
    top: ${props => props.$random(100)}%;
    left: ${props => props.$random(100)}%;
    animation-duration: ${props => props.$random(animationDuration * 10) / 10 + 10}s;
    animation-delay: ${props => (props.$random((animationDuration + 10) * 10) / 10) * -1}s;
    transform-origin: ${props => props.$random(50) - 25}vw ${props => props.$random(50) - 25}vh;
    box-shadow: ${props => particleSize * 2 * (props.$random() > 0.5 ? -1 : 1)}vmin 0
        ${props => (props.$random() + 0.5) * particleSize * 0.5}vmin currentColor;
`

// Momoized to prevent styled component from re-rendering
export const Background: FunctionComponent = memo(
    () => {
        const random = limit => (limit ? Math.random() * (limit - 1) + 1 : Math.random())
        const bubbles = new Array(20).fill(0).map((_, index) => <Bubble $random={random} key={index} />)

        return <Wrapper className="background">{bubbles}</Wrapper>
    },
    () => true
)
