import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a parentName="h2" {...{
        "href": "https://play.google.com/store/apps/details?id=com.cmgcode.bugblocks"
      }}>{`Bug Blocks`}</a></h2>
    <p>{`Bug blocks is my most recent side project: a mobile application developed for both Android and iOS.
The offline game was inspired by the joys of debugging code and tries to replicate this experience in a non-technical context.
While the fundamental gameplay is simple, the game does not leverage any game engine, meaning all mechanics were implemented from scratch in Typescript.`}</p>
    <p>{`From an educational perspective, the application developed my skills in React-Native, user acquisition and test-driven development.`}</p>
    <h2><a parentName="h2" {...{
        "href": "./education"
      }}>{`AR Based BCI Control for Autonomous Agents`}</a></h2>
    <p>{`This project represents the culmination of my academic expertise to date.
As part of my undergraduate studies, I undertook an engineering capstone research project to test the performance of an augmented-reality based brain-computer interface control system.`}</p>
    <p>{`In this project, the user wears an augmented reality headset (Microsoft Hololens) and visually focuses on a user interface. Simultaneously, a cap monitors brain activity to determine how the user is interacting with the system and moves a robot accordingly.`}</p>
    <p>{`My work in this field received the `}<strong parentName="p">{`UTS-IEEE Technology Award`}</strong>{` for innovation and potential for commercialisation, and has led to an extension of this study in a PhD over the next few years.`}</p>
    <hr></hr>
    <h1><a parentName="h1" {...{
        "href": "./projects"
      }}>{`See More`}</a></h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      